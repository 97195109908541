.disable-mfa-card, .disable-mfa-card * {
    opacity: .8;
    cursor: not-allowed;
    pointer-events: none;
}

.disable-mfa-card p {
    font-family: var(--font-families-normal);
    font-size: var(--spacing-03);
    color: var(--colors-neutral-gray90);
    margin-bottom: 0px;
}