html {
  /* prevents rubberband effect */
  height: 100%;
  overflow: hidden;
}

body {
  /* prevents rubberband effect */
  background-color: var(--colors-neutral-dark);
  color: var(--colors-neutral-light);
  font-family: var(--font-families-stylized), monospace;
  font-size: var(--font-sizes-normal);
  line-height: var(--line-heights-stylized);
  height: 100%;
  overflow: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-height: 45.75em; */
  position:  relative !important;
  width: 100% !important;
}

body *::-webkit-scrollbar-thumb {
  border: 2px solid transparent !important;
}

/* for some strange reason, font-family: work-sans is applying to
   everything (*). Maybe from the component lib. If I set * to anonymous
   pro with !important, I can't set .ocr-a to ocr-a font (tag vs. class priority)
   This wasn't happnening in the vite app.
*/

a {
  cursor: pointer;
  text-decoration: none;
  color: var(--colors-neutral-light);
}

ul {
  list-style-position: inside;
  margin: var(--spacing-02) 0;
  padding: 0;
}

pre {
  margin: 0;
}

.text-underline {
  text-decoration: underline;
}

.dynamic-content *,
form *,
aside *,
nav * {
  font-family: var(--font-families-stylized);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  position: absolute;
  /* min-height: 45.75em; */
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-baseline {
  align-items: baseline;
  align-content: baseline;
}

.align-center {
  align-items: center;
  align-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-start {
  justify-items: flex-start;
  justify-content: flex-start;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-underline:hover {
  text-decoration: underline;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(var(--radii-large));
  background-color: var(--colors-semi-transparent-dark20);
  z-index: 10;
}

.prev-page-arrow,
.next-page-arrow,
.rotate-background {
  align-content: center;
  background-color: var(--colors-semi-transparent-dark50);
  backdrop-filter: blur(var(--radii-large));
  border-radius: var(--radii-full);
  box-shadow: inset 0 0 0 var(--border-widths-normal) var(--colors-semi-transparent-light30);
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  height: var(--spacing-10);
  justify-items: center;
  position: absolute;
  width: var(--spacing-10);
  z-index: 2;
}

.prev-page-arrow,
.next-page-arrow {
  top: 50%;
  transform: translate(0, -50%);
  top: 50%;
}

.prev-page-arrow i,
.next-page-arrow i,
.rotate-background i,
.rotate-background img {
  height: calc(var(--spacing-04) + var(--spacing-00half));
  margin: 0;
  width: calc(var(--spacing-04) + var(--spacing-00half));
}

.prev-page-arrow {
  left: var(--spacing-06);
}

.next-page-arrow {
  right: calc(var(--spacing-06) + var(--spacing-04));
}

.rotate-wrapper {
  width: 100%;
  height: fit-content;
  text-align: right;
  margin-bottom: var(--spacing-04);
  position: relative;
}

.rotate-background {
  right: 0;
  bottom: 0;
}

/* modal2 move scrollbar to right */
#demo-wrapper ._2394q {
  padding-right: 12px !important;
}

#nav-cover {
  position: fixed;
  top: var(--spacing-12);
  left: 0;
  width: calc(100% - var(--spacing-12));
  height: 100%;
  z-index: 10;
}

.checkbox-container {
  display: flex;
  align-items: center;

  margin-bottom: var(--spacing-02);
}

.checkbox-container label {
  margin-bottom: -.5em;
  margin-left: var(--spacing-02);
}

.overflow-hidden {
  overflow: hidden !important;
}

label {
  font-size: var(--font-sizes-normal);
}

select {
  cursor: pointer;
}

.custom-input {
  font-size: var(--font-sizes-normal);
  border: 1px solid #9b9b9b;
  border-radius: 0 !important;
  border-top: none;
  border-right: none;
  padding: var(--spacing-02);
  box-sizing: border-box;
  display: block;
  width: 100%;
  background-color: transparent !important;
  color: var(--colors-neutral-light);
  padding: var(--spacing-02) !important;
}

.custom-input:autofill {
  background: none !important;
}

.custom-input:focus,
.custom-input:active {
  outline: none !important;
  border-color: var(--colors-brand-bright-blue) !important;
  background: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--colors-neutral-light) !important;
}

.custom-input-label {
  text-align: left;
  background-color: none;
  color: var(--colors-neutral-light);
  margin-bottom: 5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weights-regular) !important;
}

.api-usage {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto calc(100% - var(--spacing-06));
}

.line-height-1 {
  line-height: 1;
}

.text-small {
  font-size: var(--font-sizes-small) !important;
}

.text-normal {
  font-size: var(--font-sizes-normal) !important;
}

.text-medium {
  font-size: var(--font-sizes-medium) !important;
}

.text-large {
  font-size: var(--font-sizes-large) !important;
}

.text-xl {
  font-size: var(--font-sizes-xl) !important;
}

.text-atlantic {
  color: var(--colors-brand-atlantic) !important;
}

.text-bright-blue {
  color: var(--colors-brand-bright-blue) !important;
}

.text-bright-green {
  color: var(--colors-brand-bright-green) !important;
}

.text-bright-orange {
  color: var(--colors-brand-bright-orange) !important;
}

.text-blue-gray {
  color: var(--colors-brand-blue-gray) !important;
}

.text-blue-gray-light {
  color: var(--colors-brand-blue-gray-light) !important;
}

.text-burnt-orange {
  color: var(--colors-brand-burnt-orange) !important;
}

.text-error {
  color: var(--colors-brand-state-error-contrast-light) !important;
}

.text-error-light {
  color: var(--colors-brand-state-error-contrast-dark) !important;
}

.text-french-puce {
  color: var(--colors-brand-french-puce) !important;
}

.text-gold {
  color: var(--colors-brand-gold) !important;
}

.text-japanese-laurel {
  color: var(--colors-brand-japanese-laurel) !important;
}

.text-light-blue {
  color: var(--colors-brand-light-blue) !important;
}
.text-metallic-blue {
  color: var(--colors-brand-metallic-blue) !important;
}

.text-salem {
  color: var(--colors-brand-salem) !important;
}

.text-success {
  color: var(--colors-brand-state-success-contrast-light) !important;
}

.text-success-light {
  color: var(--colors-brand-state-success-contrast-dark) !important;
}

.background-atlantic {
  background-color: var(--colors-brand-atlantic) !important;
}

.background-bright-blue {
  background-color: var(--colors-brand-bright-blue) !important;
}

.background-bright-green {
  background-color: var(--colors-brand-bright-green) !important;
}

.background-bright-orange {
  background-color: var(--colors-brand-bright-orange) !important;
}

.background-blue-gray {
  background-color: var(--colors-brand-blue-gray) !important;
}

.background-blue-gray-light {
  background-color: var(--colors-brand-blue-gray-light) !important;
}

.background-burnt-orange {
  background-color: var(--colors-brand-burnt-orange) !important;
}

.background-dark {
  background-color: var(--colors-neutral-dark) !important;
}

.background-error {
  background-color: var(--colors-brand-state-error-contrast-light) !important;
}

.background-error-light {
  background-color: var(--colors-brand-state-error-contrast-dark) !important;
}

.background-french-puce {
  background-color: var(--colors-brand-french-puce) !important;
}

.background-gray-5 {
  background-color: var(--colors-neutral-gray5) !important;
}

.background-gray-15 {
  background-color: var(--colors-neutral-gray15) !important;
}

.background-gray-20 {
  background-color: var(--colors-neutral-gray20) !important;
}

.background-gold {
  background-color: var(--colors-brand-gold) !important;
}

.background-japanese-laurel {
  background-color: var(--colors-brand-japanese-laurel) !important;
}

.background-metallic-blue {
  background-color: var(--colors-brand-metallic-blue) !important;
}

.background-salem {
  background-color: var(--colors-brand-salem) !important;
}

.background-success {
  background-color: var(--colors-brand-state-success-contrast-light) !important;
}

.background-success-light {
  background-color: var(--colors-brand-state-success-contrast-dark) !important;
}

.gap-group,
.gap-group-medium,
.gap-group-small,
.gap-group-columns {
  align-content: start;
  display: grid;
  justify-items: start;
}

.gap-group {
  gap: var(--spacing-04) !important;
}

.gap-group-large {
  gap: var(--spacing-05) !important;
}

.gap-group-medium {
  gap: var(--spacing-03) !important;
}

.gap-group-small {
  gap: var(--spacing-01) !important;
}

.transparent {
  opacity: var(--opacities-transparent);
}

.text-align-right {
  text-align: right;
}

.capitalize {
  text-transform: capitalize;
}

.gap-group-columns {
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: var(--spacing-04);
  width: 100%;
}

.gap-group-columns-auto {
  grid-auto-columns: auto;
}

button {
  appearance: none;
  border-radius: 0;
}

.custom-btn {
  width: 100%;
  height: var(--spacing-12);
  background: rgb(65, 65, 65);
  color: var(--colors-brand-bright-green);
  border: var(--border-widths-normal) solid var(--colors-neutral-gray50);
  transition: calc(1s * var(--durations-medium));
  cursor: pointer;
}

.center-text {
  text-align: center !important;
}

.font-weight-regular {
  font-weight: var(--font-weights-regular);
}

.disabled {
  opacity: 0.2;
  cursor: not-allowed !important;
}

.disabled-btn {
  background: rgb(83, 83, 83) !important;
  color: rgb(165, 165, 165) !important;
  cursor: not-allowed !important;
}

.font-weight-medium {
  font-weight: var(--font-weights-medium);
}

.font-weight-bold {
  font-weight: var(--font-weights-bold);
}

#disabled-btn {
  background: rgb(83, 83, 83) !important;
  color: rgb(165, 165, 165) !important;
  cursor: not-allowed !important;
}

.disabled-btn:hover {
  background: rgb(83, 83, 83);
  border: 1px solid rgb(144, 144, 144);
  color: rgb(165, 165, 165);
  box-shadow: none;
}

#disabled-btn:hover {
  background: rgb(83, 83, 83);
  border: 1px solid rgb(144, 144, 144);
  color: rgb(165, 165, 165);
  box-shadow: none;
}

button {
  appearance: none;
  border-radius: 0;
  font-weight: bold;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.custom-btn:focus {
  background: rgb(28, 28, 28);
  border-color: var(--colors-neutral-light);
}

.disabled-btn:focus {
  background: rgb(83, 83, 83);
  border: 1px solid rgb(144, 144, 144);
  color: rgb(165, 165, 165);
  box-shadow: none;
}

#disabled-btn:focus {
  background: rgb(83, 83, 83);
  border: 1px solid rgb(144, 144, 144);
  color: rgb(165, 165, 165);
  box-shadow: none;
}

.custom-btn:active {
  background: black;
  border-color: var(--colors-neutral-light);
}

.disabled-btn:active {
  background: rgb(83, 83, 83);
  border: 1px solid rgb(144, 144, 144);
  color: rgb(165, 165, 165);
  box-shadow: none;
}

#disabled-btn:active {
  background: rgb(83, 83, 83);
  border: 1px solid rgb(144, 144, 144);
  color: rgb(165, 165, 165);
  box-shadow: none;
}

.custom-input:focus {
  outline: none !important;
}

#disabled-input, #disabled-input:focus, #disabled-input:active {
  border-color:#9B9B9B !important;
  cursor: not-allowed;
}

.forgot-pw-btn {
  width: 100%;
  height: var(--spacing-12);
  color: var(--colors-neutral-light);
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}

#background-img {
  position: fixed;
  top: 0;
  right: 0;
  min-width: 100vw;
}

.eva-icon,
.eva {
  height: var(--spacing-04);
  margin-right: var(--spacing-02);
  width: var(--spacing-04);
}

.page-arrow .eva{
  margin-right: 0;
}

.eva svg {
  height: 100%;
  margin: 0;
  width: 100%;
}

.eva-icon {
  filter: invert(95%) sepia(100%) saturate(14%) hue-rotate(213deg)
    brightness(104%) contrast(104%) !important;
}

@media screen and (max-width: 768px) {
  .eva-icon,
  .eva {
    height: var(--spacing-03);
    width: var(--spacing-03);
  }
}

.toggle-container {
  cursor: pointer;
  height: var(--spacing-04);
  margin-right: var(--spacing-02);
  position: relative;
  width: var(--spacing-04);
  overflow-x: visible !important;
}

@media screen and (max-width: 768px) {
  .toggle-container {
    height: var(--spacing-03);
    width: var(--spacing-03);
  }
}

.toggle-container .eva {
  height: 100%;
  margin: 0;
  width: 100%;
}

.toggle-container > * {
  transition: all calc(1s * var(--durations-short));
}

.toggle-container > *:first-child {
  opacity: var(--opacities-transparent);
}

.toggle-container > *:last-child {
  top: 0;
  left: 0;
  position: absolute;
  filter: none;
  color: var(--colors-neutral-light);
}

.toggle-container.toggled > *:first-child {
  opacity: var(--opacities-opaque);
  color: var(--colors-neutral-light);
  filter: none;
}

.toggle-container.toggled > *:last-child {
  opacity: var(--opacities-transparent);
}

.arrow {
  color: var(--colors-neutral-light);
  margin-right: var(--spacing-02);
  font-weight: normal !important;
}

.custom-card {
  border-radius: var(--radii-rounded);
  box-shadow: var(--shadows-blur-dark-small),
    inset 0 0 0 var(--border-widths-normal)
      var(--colors-semi-transparent-light10);
  background-color: var(--colors-neutral-dark);
  padding: var(--spacing-03);
  box-sizing: border-box;
  /* margin-bottom: var(--spacing-02); */
}

.custom-card p {
  margin: 0;
}

.custom-card .eva-icon,
.custom-card i.eva {
  margin-bottom: 0;
}

.card-title {
  font-size: var(--font-sizes-medium);
}

.metric-card {
  background: var(--colors-brand-salem);
  min-width: 0;
  /* overflow: scroll; */
}

.metric-card-content {
  align-items: center;
  display: grid;
  grid-template-columns: var(--spacing-06) auto auto;
  grid-template-areas:
    "j k k"
    ". l m";
}

.metric-card .eva-icon {
  height: var(--spacing-04);
  width: auto;
}

.overflow-auto {
  overflow: auto;
}

.cursor-grab {
  cursor: grab;
}

.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.upload-container {
  position: relative;
  background-color: var(--colors-neutral-gray10);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: var(--spacing-02) var(--spacing-02) var(--spacing-02)
    var(--spacing-03);
  width: auto !important;
}

.password-change-icon {
  cursor: pointer;
  pointer-events: auto;
}

.upload-container-center {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  flex-direction: column;
  gap: var(--spacing-02);
  grid-template-rows: 1fr min-content;
  height: 80%;
  justify-content: center;
  left: 50%;
  justify-items: center;
  max-height: calc(var(--spacing-64) * 1.5);
  padding-top: var(--spacing-04);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  cursor: pointer;
}

/* .demo-label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 25% 10%;
  transform: none;
  width: 100%;
} */

.upload-container img,
.upload-container i {
  height: auto !important;
  width: auto !important;
  margin-bottom: 50px;
}

.upload-container i {
  opacity: var(--opacities-10);
}

.upload-container img {
  filter: invert(9%) saturate(7061%) hue-rotate(173deg) brightness(100%)
    contrast(65%) !important;
}

.full-width {
  width: 100%;
}

.custom-card .eva-icon {
  height: 15px;
}

.scroll-card {
  position: relative;
  overflow: hidden;
  padding-right: 0 !important;
}

.scroll-card-header {
  position: absolute;
  width: calc(100% - var(--spacing-06));
  box-sizing: border-box;
}

.scroll-card-bottom {
  position: absolute;
  top: calc(var(--spacing-08) + 2px);
  overflow: scroll;
  height: calc(100% - var(--spacing-08) - 2px);
  width: calc(100% - var(--spacing-06));
  scroll-padding: 0;
}

.dash {
  height: 1px;
  width: 21px;
  margin-bottom: var(--spacing-02);
}

.invisible {
  display: none;
}

.visible {
  display: block;
}

.display-block {
  display: block;
}

textarea {
  background: #3a3a3a;
  color: #86ca94;
  padding: var(--spacing-02);
  width: 100%;
  resize: none;
  margin: var(--spacing-02) 0;
  height: 1var (--spacing-04);
  box-sizing: border-box;
}

textarea:focus {
  outline: none !important;
}

.padding-2 {
  padding: var(--spacing-02)
}

.padding-3 {
  padding: var(--spacing-03)
}

.padding-4 {
  padding: var(--spacing-04)
}

.padding-5 {
  padding: var(--spacing-05)
}

.padding-6 {
  padding: var(--spacing-06)
}

#model-menu {
  margin-right: 10px;
}

.slide-in {
  transform: translate(-50%, -50%);
  left: 50%;
  opacity: 1;
  top: 50%;
}

.slide-out {
  transform: translate(-50%, -50%);
  opacity: 0;
  left: -50%;
  top: 50%;
}

*::-webkit-scrollbar {
  display: initial !important;
  /* width: 17px; */
}

/* * {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.show-scrollbar {
  overflow-y: auto;
  -ms-overflow-style: initial !important;
  scrollbar-width: initial !important;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

tbody {
  -ms-overflow-style: initial !important;
  scrollbar-width: initial !important;
  width: calc(100% - 1px);
  margin-left: 1px;
}

.show-scrollbar::-webkit-scrollbar-track {
  display: initial !important;
}

.show-scrollbar::-webkit-scrollbar {
  display: initial !important;
  width: 12px;
}

.show-scrollbar::-webkit-scrollbar:horizontal {
  border-top: solid var(--border-widths-normal)
    var(--scrollbar-color-track-border);
}

.show-scrollbar::-webkit-scrollbar:vertical {
  border-left: solid var(--border-widths-normal)
    var(--scrollbar-color-track-border);
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: var(--scrollbar-color-thumb-bg) !important;
  border-radius: var(--radii-full) !important;
  border: solid calc((var(--border-widths-large))) transparent;
  box-shadow: inset 0 0 0 var(--border-widths-normal)
    var(--scrollbar-color-thumb-border);
  box-sizing: border-box;
  display: initial !important;
}

.show-scrollbar::-webkit-scrollbar-corner {
  background: transparent !important;
  display: initial !important;
}

.align-center {
  display: flex;
  align-items: center;
}

@keyframes expand-in {
  0% {
    opacity: var(--opacities-transparent);
  }
  100% {
    opacity: var(--opacities-opaque);
  }
}

.animate-in-expand {
  animation: expand-in calc(1s * var(--durations-long)) ease-in-out forwards;
  transform-origin: top center;
}

.animate-out-expand {
  animation: expand-in calc(1s * var(--durations-long)) ease-in-out forwards;
  transform-origin: top center;
}

.white-space-pre-line {
  white-space: pre-line !important;
}

.notification-class * {
  overflow-wrap: break-word;
  overflow: hidden;
}

.annotationLayer {
  display: none !important;
}

@keyframes expand-in {
  0% {
    opacity: var(--opacities-transparent);
    transform: scaleY(0);
    height: 0%;
  }
  50% {
    height: 100%;
  }
  100% {
    opacity: var(--opacities-opaque);
    transform: scaleY(1);
    height: 100%;
  }
}

@keyframes expand-out {
  0% {
    opacity: var(--opacities-opaque);
    transform: scaleY(1) perspective(100rem) rotateX(45deg);
    height: 100%;
  }
  50% {
    height: 100%;
  }
  100% {
    opacity: var(--opacities-transparent);
    transform: scaleY(0) perspective(80rem);
    height: 0%;
  }
}
